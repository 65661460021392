.subTable-dash thead tr th {
    background-color: #1B1C20;
    color: #fff;
    font-size: 1.15em;
    height: 50px;
    padding: 5px 10px;
    border-left: 2px solid #fff;
}

.subTable-dash tbody tr td {
    padding: 3px 10px;
    border-left: 2px solid #fff;
}

.subTable-dash tbody tr:nth-child(even) {
    background-color: #eeeeef;
}
.STRight tbody tr:nth-child(even){
	background-color: white;
}

.STRight tbody tr{
	background-color: #eeeeef;
}

.STLeft{
	width: 80% !important;
	float: left;
}

.HeadArrowsST{
	top: 13px !important;
}

.span-rows{
    height: 39px;
    border-left: 0px solid #fff !important;	
    border-bottom:  0px solid #fff !important;	
	text-align: center;
}

.doble-row {
	width: 160px
}

.doble-row .span-rows:first-child {
	width: 60px
}

.doble-row .span-rows:last-child {
	width: 100px
}

.sprr{
	border-left: 2px solid #fff !important;	
}



.STRight{
	width: 20% !important;
	float: left;
	margin-left: 0px;
	overflow-y: auto;
}
.STRight thead tr>th{
	position: static;
	text-align: center;
	height: 28.5px;
 	padding: 0px 0px;
}

.subHeaderContainer{
	padding: 0px 0px !important;
	background-color: #1B1C20 !important; 
}

.subHeaders{
	width: 300px !important;
	border-bottom:  0px solid #fff !important;
	height: 13px !important;
	background-color: #1B1C20;
    color: #fff !important;
	border-left: 0px solid !important;
	position: relative;
}
.doble-row{
	padding: 0px 0px !important;
}
.subHeaderR{
	text-align: right !important;
}

.alcance-green {
    background-color: green;
}

.alcance-yellow {
    background-color: yellow
}

.alcance-red {
    background-color: red
}

.alcance-grey{
	background-color: grey;
}