@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  outline: none;
}
.Mui-error{
  display:none
}
.LoginPage {
    background: rgb(2,0,36);
    background: linear-gradient(340deg, rgba(2,0,36,1) 0%, rgba(27,28,32,1) 6%, rgba(231,36,0,1) 100%);
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 10vh;
}

.LoginBox {
    width: 400px;
    height: 300px;
    background-color: white;
    margin: 0 auto;
    box-shadow: 0px 0px 18px -4px rgba(0,0,0,0.47);
    text-align: center;
    padding: 30px;
}

.LoginBox img {
    width: 100%;
    margin-bottom: 40px;
}

.LoginBox label {
    display: block;
    text-align: left;
    font-size: 1.2em;
}

.LoginBox input {
    width: 100%;
    margin-bottom: 10px;
    padding: 4px 20px;
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.LoginBox button {
    background-color: #0000;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    margin-top: 50px;
}

.LoginBox button:hover {
    opacity: .75;
}

.LoginBox select {
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;
    padding: 4px 20px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    font-size: 16px
}

.input-error {
    border-color: #a50000!important;
}

.error-message {
    color: #a50000
}

.MuiCircularProgress-colorPrimary {
    color: #a50000!important
}

footer {
    background-color: #ddd;
    width: 100%;
    display: block;
    padding: 5px 20px;
    font-size: 13px;
    height: 25px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
}

footer span:first-child{
    float: left;
}

footer span:last-child{
    float: right
}
header {
    height: 80px;
    width: 100%;
    border-bottom: 10px solid #E72400;
}

header .logo {
    height: 100%;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    box-sizing: border-box;
    padding: 15px;
}

header .logo img {
    height: 115%;
}

header .logo p {
    font-size: 20px;
    margin-left: 10px;
    border-left: 1px solid #E72400;
    height: 40px;
    padding-top: 15px;
    padding-left: 10px;
}

header .page-title h1 {
    text-transform: uppercase;
    font-size: 2.3em;
    padding-top: 18px;
    margin: 0;
    float: left;
    margin-left: 40px;
}

header .user-info {
    float: right;
    display: flex;
    margin-top: 15px;
    margin-right: 25px;
}

header .user-info > div {
    margin-right: 10px;
}

header .user-info .user-name {
    margin: 0;
    font-size: 1.5em;
    text-transform: uppercase;
    text-align: right;
}

header .user-info .user-rol {
    margin: 0;
    font-size: 1em;
    text-align: right;
}

header .user-info svg {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
}

header .user-info svg:hover {
    opacity: .75;
}

header .user-info img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    border: 2px solid #1B1C20
}

header .user-info img:hover {
    opacity: .75;
}

.NewModal {
  background-color: #fff;
  outline: 0;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
  position: relative;
}

.NewModal h3 {
  margin: 0;
  background-color: #1B1C20;
  color: #fff;
  padding: 10px 25px;
  font-size: 2em;
  font-weight: 400;
  border-bottom: 10px solid #E72400;
}

.NewModal-container {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  max-height: calc(100vh - 210px);
}

.NewModal-footer {
  text-align: center;
  padding: 15px 0 15px 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.NewModal .gray-btn {
  background-color: #7E7D82;
  border: none;
  color: #fff;
  border-radius: 10px;
  width: 120px;
  padding: 6px 0;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  cursor: pointer;
  -webkit-transition: all ease .5s;
  transition: all ease .5s;
}

.NewModal .gray-btn:hover {
  opacity: .75;
}

.NewModal .black-btn {
  background-color: #1B1C20;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease .5s;
  transition: all ease .5s;
}

.NewModal .delete-button {
  position: absolute;
  left: 30px;
  background-color: #E72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease .5s;
  transition: all ease .5s;
}

.btn-danger {
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.btn-success {
  background-color: #4caf50;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.btn-primary {
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.pos-abs-r {
  position: absolute;
  right: 2rem;
}

.NewModal .black-btn:hover {
  opacity: .75;
}

.NewModal>svg {
  position: absolute;
  color: #ddd;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.NewModal>svg:hover {
  color: #fff;
}
aside {
    background-color: #1B1C20;
    width: 220px;
    height: calc(100vh - 90px);
    float: left;
    overflow-y: auto;
    overflow-x: hidden
}

aside ul {
    padding: 0;
    margin: 0;
}

aside ul li a {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 10px 0 10px 20px;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    padding-left: 50px;
}

aside ul li a svg {
    width: 20px;
    margin-right: 10px;
    position: absolute;
    left: 20px;
}

aside ul li:hover > a {
    background-color: #C6C6C5;
    color: #1B1C20;
    border-left: 5px solid #E72400;
}

aside ul li:hover > a svg path {
    fill: black;
}

aside ul li.active > a {
    background-color: #C6C6C5;
    color: #1B1C20;
    border-left: 5px solid #E72400;
}

aside ul li.active > a svg path {
    fill: black;
}

aside ul .menu-title {
    font-size: 1.75em;
    text-transform: uppercase;
    color: #fff;
    padding: 15px 0 15px 20px;
}

aside ul .menu-title svg {
    width: 25px;
    margin-right: 10px
}

.reportes-tab {
    background-color: rgb(0,0,0);
    width: 220px;
    right: 0;
    z-index: 999;
    box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.42);
}

.reportes-tab a {
    font-size: 16px;
    display: block;
    padding: 6px 8px;
    margin-left: 20px;
}

.reportes-tab a:hover {
    background-color: #333;
    border-left: 5px solid #E72400;
}

.reportes-tab a.active {
    background-color: #333;
    border-left: 5px solid #E72400;
}
.view-title {
    width: 100%;
    height: 26px;
    background-color: #7E7D82;
    color: #fff;
    margin: 0;
    padding: 6px 20px;
    margin: 6px 30px;
    font-size: 1.3em;
    border-radius: 50px;
}
#view-title-action {
    width: 50% !important;
    margin-right:0px !important;
    display: inline-block;
}
.filtros-comparadores{
    float: right !important;
    display: inline-flex !important;
    color:black !important;
}
.filtros-comparadores .select-search__menu{
    max-height: 140px;
    display: flex;
    overflow-x: hidden !important;
}



.filtros-comparadores p{
    margin:0px;
    color:white
}
#accion-comparar{
    width: 20% !important;
    margin-left:0px !important;
    float: right;
    display: inline-block;
    text-align: center;
    cursor:pointer
}
#accion-clear{
    max-width: 20px;
    max-height: 20px;
    margin-left:0px !important;
    float: right;
    
    padding:6px 8px 8px 6px;
    border-radius: 300px;
    cursor:pointer
}
#accion-clear svg{
    margin:0px !important
}
#accion-comparar:hover{
    background-color:#a0a0a0;
}
.contenedor-franja-header{
    width: 100%;
}
main {
    float: left;
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 90px);
    overflow: auto;
    width: calc(100% - 220px);
    position: relative;
}

main .contenedor {
    margin: 0 30px;
    width: calc(100% - 60px);
    height: calc(100vh - 145px);
}

.infobox {
    width: 100%;
    border: 2px solid #b3b3b3;
    margin: 0 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 10px 0;
    height: 86px;
    box-sizing: border-box;
    position: relative;
}

.infobox svg {
    width: 40px;
}

.infobox .infobox-title {
    color: #7E7D82;
    font-size: 1.25em;
    margin: 0;
}

.infobox .infobox-total {
    color: #7e7d82;
    font-size: 2em;
    margin: 0;
    font-weight: 700;
}

.infobox > div {
    margin-left: 10px;
}
.info-boxes {
    max-height: 225px;
    overflow-y: auto;
}

.tab-filtros {
    align-items: center;
}

.tab-filtros > * {
    display: inline-block
}

.tab-filtros p {
    margin: 15px 5px 15px 12px;
    font-size: 14px;
}

.tab-filtros select {
    height: 30px;
    padding: 0 20px 0 5px;
    border-radius: 3px;
}

.select-dashboard {
    min-width: 300px;
}

.select-search {
    z-index: 999999;
    width: 58px;
    font-size: 14px;
}
.mas-ancho{
    
    width: 70px !important;
}
.select-alcance {
    z-index: 999999;
    width: 180px;
    font-size: 14px;
}

.select-search__control {
    min-height: 30px!important;
    height: 30px!important;
    border-color: #aaa!important;
}

.select-search__indicators {
    padding: 6px 0px!important;
    height: 30px!important;
}

.select-search__indicator-separator {
    margin-right: 0px!important;
}

.select-search__indicator {
    padding: 2px!important
}

.select-weeks {
    z-index: 9999999;
    width: 57px;
    font-size: 14px;
}

.select-tipos {
    z-index: 9999999;
    width: 120px;
    font-size: 14px
}

.select-alcance-abs {
    font-size: 12px !important;
    position: absolute;
    width: 150px;
    background-color: white;
    z-index: 9999;
    top: 9px;
    left: 10px;
    pointer-events: none
}

.error-input {
    border-color: #ff9494!important;
}

.select-modal.error-input > div {
    border-color: #ff9494!important
}

.MuiSnackbar-root {
    z-index: 99999999!important
}

.inputs-modal input {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 7px 18px;
    border-radius: 15px;
    outline: none;
}

.contenedor-comparativa * {
    display: inline-block;
}

.contenedor-comparativa p {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    font-size: 13px;
}

@media only screen and (max-width: 1300px){
    .contenedor-comparativa p {
        font-size: 15px;
    }
}

.contenedor-comparativa span {
    font-size: 18px
}

.contenedor-materia {
    position: absolute;
}

.materia-left {
    bottom: 0;
    left: 0;
    text-align: right;
    width: 52%;
}

.materia-right {
    bottom: 0;
    right: 0;
    text-align: left;
    width: 45%;
}

.infobox .MuiSvgIcon-root:nth-child(2),
.infobox .MuiSvgIcon-root:nth-child(3) {
    width: 30px
}

.infobox .MuiSvgIcon-root:nth-child(1) {
    fill: rgb(231, 36, 0);
    font-size: 70px;
}
.search-input-desempeno-grupo{
    right: 40px;
    margin-top: 5px;
    position: absolute;
}

.contenendor-indicadores{
    width: 100% !important;
}
.franja-indicadores{
    display: inline-flex;
}
.info-boxes{
     width: 100% !important;
}
.info-boxes > div{
    display: flex;
    position: relative;
    margin-top: 25px;
}
.info-boxes > div > p{
    position: absolute;
    top:-45px;
    left:10px;
    font-size: 22px;
}

#container-chart-bar{
    position: relative;
    width: 100%;
    margin:auto;
    margin-top: 40px;
}
#container-chart-bar canvas{
    height: calc(100vh - 490px) !important;
}
#container-chart-bar .contenedor-multiselect{
    position:absolute !important;
    left:150px;
    top:-30px
}

#container-chart-bar>span{
    position:absolute !important;
    left:0px;
    top:-30px
}
#container-chart-bar .contenedor-multiselect .contenedor-aceptar-opciones{
    width: 700px;
}
#container-chart-bar .contenedor-multiselect .contenedor-aceptar-opciones>input{
    width: 98%;
    border-radius: 50px;
    outline: none;
    border-width: 0px;
    border-bottom-width:1px
}
.tabs-list {
    display: flex;
}

.tab {
    border-radius: 20px 20px 0 0;
    padding: 5px 20px;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
}

.tab:hover {
    z-index: 99
}

.tab.active {
    z-index: 9;
    background-color: #E72400 !important;
}

.tab:nth-child(1){
    background-color: #1B1C20;
    color: #fff;
}

.tab:nth-child(2){
    background-color: #1B1C20;
    color: #fff;
    margin-left: -10px;
}

.tab:nth-child(3){
    margin-left: -10px;
    background-color: #1B1C20;
    color: #fff;
}

.tab-content {
    height: calc(100% - 40px);
    border-left: 1px solid #b3b3b3;
    border-right: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    border-radius: 0 10px 10px 10px;
    overflow: hidden;
    padding: 0;
    margin-top: -1px;
}

.tab-header {
    height: 15px;
}
.table-dash thead tr th {
    background-color: #1B1C20;
    color: #fff;
    font-size: 1.15em;
    padding: 5px 10px;
    border-left: 2px solid #fff;
}

.table-dash tbody tr td {
    padding: 3px 10px;
    border-left: 2px solid #fff;
}

.table-dash tbody tr:nth-child(even) {
    background-color: #eeeeef;
}

.HeadArrows {
    position: absolute;
    top: -2px;
    right: 0;
}

.HeadArrows svg {
    display: block;
}

.HeadArrows svg:nth-child(2) {
    margin-top: -15px;
}

.HeadArrows-con {
    position: absolute;
    top: -4px;
    right: -11px;
}

.HeadArrows-con svg {
    display: block;
}

.HeadArrows-con svg:nth-child(2) {
    margin-top: -15px;
}


.HeadCell {
    cursor: pointer;
    position: relative;
}

.contenedor-buttons {
    text-align: center;
}

.contenedor-buttons button {
    border-top: #1a2f36 solid 2px;
    border-bottom: #1a2f36 solid 2px;
    border-left: #1a2f36 solid 2px;
    border-right: none;
    background-color: white;
    height: 40px;
    color: #1a2f36;
    padding: 0 10px;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    margin: 0;
}

.contenedor-buttons button:focus {
    outline: 0;
}

.contenedor-buttons button:hover {
    background-color: #1a2f36;
    color: white
}

.contenedor-buttons button:last-child {
    border-right: #1a2f36 solid 2px;
    border-radius: 0 10px 10px 0;
    padding: 0 15px;
}

.contenedor-buttons button:first-child {
    border-radius: 10px 0 0 10px;
    padding: 0 15px;
}

.contenedor-buttons button.active {
    background-color: #1a2f36;
    color: white;
}
.pagina-inicio-btn{
    margin-right: 10px !important; 
    border-right: #1a2f36 solid 2px !important;
}
.pagina-fin-btn{
    margin-left: 10px !important; 
    border-left: #1a2f36 solid 2px !important;
}
.pagina-siguiente-btn{
    border-right: #1a2f36 solid 2px !important;
}
.EditButton { 
    background-color: #7E7D82;
    color: white;
    border: none;
    padding: 3px 25px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
}

.EditButton:hover {
    opacity: .8;
}

.NewButton {
    background-color: #1B1C20;
    color: white;
    border: none;
    padding: 5px 15px 5px 5px;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s; 
    display: inline-flex;
    justify-content: center;
    align-self: center;
}

.NewButton span {
    margin-top: 3px!important;
    margin-left: 5px!important;
    font-size: 16px;
}

.NewButton:hover {
    opacity: .8
}

.BlackButton {
    background-color: #1B1C20;
    color: white;
    border: none;
    padding: 7px 10px;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s; 
    display: inline-flex;
    justify-content: center;
    align-self: center;
}

.BlackButton span {
    margin-top: 4px;
    margin-left: 5px;
    font-size: 16px
}

.BlackButton:hover {
    opacity: .8
}

.ButtonCancel {
    background-color: #999999;
    cursor: not-allowed;
    pointer-events: none;
}
.LargeModal-grid {
  display: grid;
}

.LargeModal {
  background-color: #fff;
  min-width: 80%;
  width: 90%;
  outline: 0;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
  position: relative;
}

.LargeModal h3 {
  margin: 0;
  background-color: #1B1C20;
  color: #fff;
  padding: 10px 25px;
  font-size: 2em;
  font-weight: 400;
  border-bottom: 10px solid #E72400;
}

.LargeModal-container {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.LargeModal-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

.LargeModal-footer div {
  display: flex;
  align-items: center;
}

.LargeModal>svg {
  position: absolute;
  color: #ddd;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.LargeModal>svg:hover {
  color: #fff;
}

.btn-download {
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.btn-upload {
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.btn-danger {
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.btn-success {
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.btn-primary {
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.btn-secondary {
  background-color: #7E7D82;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.btn-black {
  background-color: #1B1C20;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.pos-abs-r {
  position: absolute;
  right: 2rem;
}
.SearchInput::-webkit-input-placeholder {
    padding-left: 65px;
}

.SearchInput::-moz-placeholder {
    padding-left: 65px;
}

.SearchInput:-ms-input-placeholder {
    padding-left: 65px;
}

.SearchInput::-ms-input-placeholder {
    padding-left: 65px;
}

.SearchInput::placeholder {
    padding-left: 65px;
}

.SearchInput {
    width: 180px;
    height: 35px;
    border-radius: 15px;
    border: 1px solid #ccc;
    padding: 0 15px;
    font-size: 15px;
}

.SearchInput:focus::-webkit-input-placeholder {
    visibility: hidden;
}

.SearchInput:focus::-moz-placeholder {
    visibility: hidden;
}

.SearchInput:focus:-ms-input-placeholder {
    visibility: hidden;
}

.SearchInput:focus::-ms-input-placeholder {
    visibility: hidden;
}

.SearchInput:focus::placeholder {
    visibility: hidden;
}

.SearchInput:focus {
    outline: none;
}

.SearchInputIcon {
    position: absolute;
    width: 20px;
    margin-left: 55px;
    margin-top: 7px;
}


.contenedor-aceptar-opciones{
	position: fixed;
	width: 25%;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 3;
	text-align: center;
}
.div-contenedor-opciones{

	background-color: white;
	z-index: 3;
	max-height: calc(100vh - 520px);
	overflow-y: auto;
	text-align: center;
}
.contenedor-aceptar-opciones4 .div-contenedor-opciones{
	text-align: left;
	font-size: 15px;
	padding-left: 3px;
}
.div-contenedor-opciones::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.div-contenedor-opciones::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}
.button-aceptar-opciones{
    background-color: #1B1C20;
    border: none;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Roboto';
    margin: 0 5px;
    width: 120px;
    padding: 6px 0;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
  margin-top: 0px;
  z-index: 4;
}
.div-contenedor-opciones p{
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;
	text-align: left;
}

.div-contenedor-opciones p input{
	float: right;
	width: 30px;
}
.div-contenedor-opciones p:hover{
	background: #7E7D82
}

.principal-input-multiselect{
	text-align: center !important;
	cursor: default;
	width: 690px !important;
}

.arrow-down-select svg{
	position:absolute;
	margin-left: -40px;
	margin-top: -10px;
	font-size: 45px;
	color:#505050;
	pointer-events: none;
}

.contenedor-multiselect{
	position: relative;
	width: 700px !important;
}
#span-total-materias{
	position: absolute;
	left: 10px;
	top: 5px;
	font-size: 14px;
}

.nuevoUsuarioForm {
    display: flex;
    margin: 0 10px;
    padding-top: 10px;
}

.nuevoUsuarioForm-edit {
    margin: 0 10px;
    padding-top: 10px
}

.nuevoUsuarioForm .avatarForm, .nuevoUsuarioForm-edit .avatarForm {
    width: 208px;
    text-align: center;
    position: relative;
}

.user-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 215px 1fr;
}

.nuevoUsuarioForm label, .nuevoUsuarioForm-edit label {
    font-size: 13px;
    font-weight: 600;
    display: block;
    margin: 5px 15px;
}

.nuevoUsuarioForm input, .nuevoUsuarioForm-edit input {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 7px 18px;
    border-radius: 15px;
    outline: none;
}

.nuevoUsuarioForm input::-webkit-input-placeholder, .nuevoUsuarioForm-edit input::-webkit-input-placeholder {
    font-family: 'Roboto';
}

.nuevoUsuarioForm input::-moz-placeholder, .nuevoUsuarioForm-edit input::-moz-placeholder {
    font-family: 'Roboto';
}

.nuevoUsuarioForm input:-ms-input-placeholder, .nuevoUsuarioForm-edit input:-ms-input-placeholder {
    font-family: 'Roboto';
}

.nuevoUsuarioForm input::-ms-input-placeholder, .nuevoUsuarioForm-edit input::-ms-input-placeholder {
    font-family: 'Roboto';
}

.nuevoUsuarioForm input::placeholder, .nuevoUsuarioForm-edit input::placeholder {
    font-family: 'Roboto';
}

.nuevoUsuarioForm input:focus::-webkit-input-placeholder, .nuevoUsuarioForm-edit input:focus::-webkit-input-placeholder {
    color: transparent;
}

.nuevoUsuarioForm input:focus::-moz-placeholder, .nuevoUsuarioForm-edit input:focus::-moz-placeholder {
    color: transparent;
}

.nuevoUsuarioForm input:focus:-ms-input-placeholder, .nuevoUsuarioForm-edit input:focus:-ms-input-placeholder {
    color: transparent;
}

.nuevoUsuarioForm input:focus::-ms-input-placeholder, .nuevoUsuarioForm-edit input:focus::-ms-input-placeholder {
    color: transparent;
}

.nuevoUsuarioForm input:focus::placeholder, .nuevoUsuarioForm-edit input:focus::placeholder {
    color: transparent;
}

.avatarForm img.avatar {
    height: 140px;
    width: 140px;
    border: 5px solid #1B1C20;
    border-radius: 100%;
    margin-top: 20px;
}

.editAvatar {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 120px;
    right: 20px;
    cursor: pointer;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
}

.editAvatar:hover {
    -webkit-filter: brightness(2.5);
            filter: brightness(2.5);
}

.select-search-box::after {
    width: 0px;
    height: 0px;
    border-top: 10px solid #777;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 10px;
    right: 15px;
    content: ' ';
}

.select-search-box__options {
    margin: 0;
    padding: 0;
}

.select-search-box {
    position: relative;
}

.select-search-box--multiple {
    border-radius: 4px;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    overflow: hidden;
}

    input.select-search-box__search {
        line-height: 1;
    }

    .select-search-box--multiple .select-search-box__search {
        box-shadow: none;
    }

    .select-search-box--input .select-search-box__search {
        cursor: text;
    }

        .select-search-box__search:focus {
            cursor: text;
        }

    input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .select-search-box__select {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        background: #fff;
        overflow: auto;
        z-index: 100;
        min-height: 49px;
        border: 1px solid #ccc;
    }

    .select-search-box--multiple .select-search-box__select {
        display: block;
        position: static;
        border-top: 1px solid #eee;
        border-radius: 0;
        box-shadow: none;
    }

        .select-search-box__select--display {
            display: block;
        }

    .select-search-box__option {
        font-size: 14px;
        font-weight: 400;
        color: #616b74;
        padding: 5px 25px;
        border-top: 1px solid #eee;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .select-search-box__option:first-child {
            border-top: none;
        }

        .select-search-box__option--hover, .select-search-box__option:hover {
            background: #f4f7fa;
        }

        .select-search-box__option--selected {
            background: #54A0FF;
            color: #fff;
            border-top-color: #2184ff;
        }

            .select-search-box__option--selected:hover, .select-search-box__option--selected.select-search-box__option--hover {
                background: #2184ff;
                color: #fff;
                border-top-color: #2184ff;
            }

        .select-search-box__group {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #eee;
            position: relative;
        }

        .select-search-box__group-header {
            position: absolute;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            background: white;
            padding: 0 10px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }

.select-modal__value-container--is-multi {
    height: 30px;
    overflow: auto!important;
    margin-top: -6px;
}

.modal-switch {
    display: flex;
    align-items: center;
}

.modal-switch p {
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.centrar-texto{
    margin:auto !important;
    font-size:40px;
    text-align:center;
}

.masiva-titulo {
    margin: 0 20px;
    padding: 15px 0 5px 0;
}

.masiva-descripcion {
    margin: 0 20px;
    height: 150px;
    overflow: auto;
}

.masiva-botones {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
}

.masiva-botones button {
    margin: 25px 10px 10px 10px;
    padding: 5px 30px;
}
.select-modal__control {
    border-radius: 15px!important;
    background-color: #f9f9f9!important;
    min-height: 20px!important;
    font-size: 14px!important;
    padding-left: 7px!important;
    height: 33px!important;
}

.select-modal__value {
    margin-top: -4px;
}

.select-modal__value-container {
    position: static!important;
    position: initial!important;
}

.select-modal__control--is-focused .select-modal__placeholder {
    color: transparent;
}
.MuiInput-underline:before {
    content: none!important;
}

.MuiInputAdornment-positionEnd {
    margin-left: -45px!important;
}

.MuiIconButton-root {
    padding: 3px
}

.MuiInputBase-input {
    height: 23px!important;
    width: 120px!important
}

.MuiFormControl-root {
    border: 1px solid #ccc!important;
    padding: 0px 0px 3px 10px!important;
    border-radius: 5px!important;
    height: 30px;
}

.MuiDialog-root {
    z-index: 9999999!important;
}

.nuevoUsuarioForm .MuiFormControl-root.date-picker, .nuevoUsuarioForm-edit .MuiFormControl-root.date-picker {
    width: 100%;
    box-sizing: border-box;
    border-radius: 15px!important;
    background-color: #f9f9f9;
}

.nuevoUsuarioForm .date-picker .MuiInputBase-root input, .nuevoUsuarioForm-edit .date-picker .MuiInputBase-root input {
    border: none;
    background-color: transparent;
    margin-top: 2px;
}

.nuevoUsuarioForm .date-picker .MuiButtonBase-root, .nuevoUsuarioForm-edit .date-picker .MuiButtonBase-root {
    right: 5px;
    top: 0px;
    position: absolute;
    padding: 3px;
}

.nuevoUsuarioForm textarea.textarea-full, .nuevoUsuarioForm-edit textarea.textarea-full {
    width: 100%;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    resize: none;
}

.MuiPickersToolbar-toolbar {
    background-color: #7E7D82!important
}

.MuiPickersClock-pin {
    background-color: #000!important
}

.MuiPickersClockPointer-pointer {
    background-color: #000!important
}

.MuiPickersClockPointer-noPoint {
    background-color: #000!important
}

.MuiPickersClockPointer-thumb {
    border-color: #000!important
}

.MuiButton-textPrimary {
    color: #000!important
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #7e7d82!important
}

.MuiCheckbox-root {
    margin-left: -15px!important;
}

div[class^='makeStyles-root'] {
    z-index: 999999999999;
}


.Coordinador-Menu-Table p {
    margin: 0 0px;
    font-size: 18px;
}

.Coordinador-Table-Box {
    border: 2px solid #999;
    margin: 5px;
    border-radius: 5px;
    height: 350px;
    padding: 5px 10px;
}

.arrow-box {
    position: relative;
    width: 20px;
}

.forward-btn {
    color: green;
    width: 50px!important;
    height: 30px!important;
    position: absolute;
    cursor: pointer;
    top: -15px;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
}

.forward-btn:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
}

.backward-btn {
    color: red;
    width: 50px!important;
    height: 30px!important;
    position: absolute;
    cursor: pointer;
    top: -15px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: all ease .5s;
    transition: all ease .5s; 
}

.backward-btn:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2)
}

.date-picker input{
    width:100% !important;
}

.Calendar-Week {
    border: 1px solid black;
    padding: 2px 5px;
    background-color: black;
    color: white;
    text-align: center;
    font-size: 14px;
    position: relative;
    overflow-y: hidden;
}

.Cell-Min-Width {
    min-width: 110px;
    box-sizing: border-box;
}

.Calendar-Criterio {
    padding: 2px 5px;
    background-color: #aaa;
    border-bottom: 1px solid #777;
    border-left: 1px solid #777;
    font-size: 13px;
    height: 50px;
    overflow-y: auto;
}

.Calendar-Cell {
    border-bottom: 1px solid #999;
    border-right: 1px solid #999;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    padding-top: 15px;
    position:relative;
}

.Calendar-Cell.selected {
    background: #1e9a3f
}

.Calendar-Cell.bloqueada{
    background-color: grey;
    cursor:not-allowed
}
.Calendar-Cell.bloqueada2{
    background-color: #ccc;
    cursor:not-allowed
}

.Calendar-Cell.bloqueada2Selected{
    background-color: #6fab8f;
    cursor:not-allowed
}

.Calendar-Cell.focus {
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    border: 2px solid #5b84ba;
    margin-left: -2px;
    margin-top: -2px;
}

.Calendar-Container {
    height: calc(100% - 176px);
    margin-top: 10px;
}

.Lista-Editable-Container{
    height: calc(100% - 100px);
    margin-top: 10px;
}

.inputs-calendar {
    padding: 0 5px;
}

.inputs-calendar label {
    font-size: 11px;
}

.inputs-calendar input {
    width: 100px;
    margin-top: 12px;
    font-size: 11px;
    padding: 5px 10px;
}

.Calendar-Container-Full {
    height: calc(100% - 10px);
}

.Calendar-Criterios {
    display: grid;
    grid-template-columns: 50px 200px;
    float: left;
    height: calc(100% - 5px);
    overflow-y: hidden;
}

.Calendar-Cells {
    display: grid;
    overflow: auto;
    height: calc(100% - 5px);
}

.Calendar-Headers {
    display: grid;
    overflow-x: hidden;
}
.vinotinto{
    background-color: rgb(192,0,0) !important;
}
.negro{
    background-color:#000;
    color:white;
    cursor: pointer;
    border:solid;
    border-width: 1px;
    border-radius: 7px;
    font-weight: 1000
}
.blanco-ocultar{
    background-color:#fff;
    border-color: #fff;
    cursor:default;
}

.grey-header{
    background-color: rgb(199,199,199) !important;
    color:black;
}
.criterio-blanco{
    background-color: white !important;
    color:black;
    border-right: solid;
    border-width: 1px;
    border-color: #999;
}
.Calendar-Cells::-webkit-scrollbar-thumb {
    width: 30px;
}
.Encuadre-asignatrua{
    width: 100%;
    text-align: center;
    background-color: black;
    color:white;
}
.button-editar{
    position: absolute;
    margin-top: -10px;
}
.headers-lista-editar{
    
}
.date-ranger-container{
    position: absolute;
    margin-top: -4px;
    left: 0px;
    cursor: pointer;
}
.date-ranger-container:hover{
    color: black; 
    background-color: white;
}
.date-ranger-container-criterio{
    position:absolute;
    left:0px;
    cursor: pointer;
}
.date-ranger-container-criterio:hover{
    color: white;
    background-color:black;
}
.opciones-historial div>span {
  font-weight: 700;
  margin-right: 30px;
  margin-left: 25px;
}

.opciones-historial label {
  margin-right: 15px;
}

.opciones-historial select {
  margin-right: 30px;
  padding: 5px 20px;
  border-radius: 15px;
}

.enviado {
  font-weight: 700;
  color: #51AD32;
}

.no-enviado {
  font-weight: 700;
  color: #AC1917;
}

.opciones-bar {
  margin-bottom: 5px;
  min-height: 45px;
}

.opciones-bar>* {
  display: inline-block
}

.opciones-bar label {
  margin: 0 5px;
}

.opciones-bar span {
  margin: 0 5px
}

.opciones-bar div>span {
  margin: 0 5px 0 0px
}

.opciones-bar-reporte .tab-filtros>div>p {
  margin: 2px 0;
}

.opciones-bar-reporte .tab-filtros>div {
  margin-right: 5px;
}

.opciones-bar-reporte .date-year {
  display: block !important;
  margin-top: 0px !important
}

.opciones-bar-filter .tab-filtros>div>p {
  margin: 2px 0;
}

.opciones-bar-filter .tab-filtros>div {
  margin-right: 5px;
}

.opciones-bar-filter .date-year {
  display: block !important;
  margin-top: 0px !important
}

.fecha-historial .MuiFormControl-root {
  height: 25px;
  margin-top: -6px;
  width: 95px;
}

.fecha-historial input {
  padding-top: 3px
}
.verificacion-modal{
	max-width: 1100px;
}
.contenedor-verificacion{
	width: 90%;
	margin: auto;
	height: 100%;
	overflow-x: auto;
}
.logo-from-lista img{
	width: 170px !important;
	float: left;
	display: block;
}
.logo-from-lista{
	width: 170px;
	float: left;
	display: block;
}
.encuadre{
	border:solid;
	border-width: 1px;
	margin-top: 15px;
}
.table-header table{
	padding: 0 0 !important;
	width: 100% !important;
	border-spacing: 0px;
	border-collapse: collapse;
}

.table-header td{
	border-width: 0.5px !important;	
	width: 50% !important;
	border:solid;
	border-width: 0.5px !important;
	border-collapse: separate;
	margin:0;
	padding: 0;
}

.header-verificacion{
	height: 230px;
	border:solid;
	border-width: 0.5px !important;
}
.header-verificacion h2{
	color:#E72400;
	margin: 0px;
	float: left;
	margin-left: 40px;
	margin-top:7.5px;
} 

.franja-subtitulo{
	background-color: #7E7D82;
	border:solid;
	border-width: 0.5px !important;
	margin-top: 10px;
	text-align: center;
	font-weight: 5000;
	border-color: black;
	color: white;
}
.evaluacion-final-titulo{
	background-color: black;
	font-weight: 600;
	color:white;
	border:solid;
	border-width: 0.5px !important;
	text-align: center;
	border-color: black;	
	width: 600px;
	float: right;
	margin-top: 30px;
}


.tabla-subtitulo{
	background-color: #CCCCCC;
	font-weight: 600;
	color:black;
	border:solid;
	border-width: 0.5px !important;
	text-align: center;
	border-color: black;
}

/*Estilos de la tabla verificacion*/


.tabla-verificacion table{
	width: 100% !important;
	padding: 0 0 !important;
	border-spacing: 0px;
	padding: 0 0 !important;
	border:solid !important;
	border-color: black;
}
.tabla-verificacion table th{
	background-color: #CCCCCC;
	border:solid;
	border-width: 0.5px !important;
	border-color: black;

}
.tabla-verificacion table th:nth-child(1){
	width: 30%;
}
.tabla-verificacion>table>tr>th:nth-child(2){
	width: 60% ;
	background-color: red;
	border-color: black !important;
	color:white;
}
.tabla-verificacion table td{
	text-align: center;
	border:solid;
	border-width: 0.5px !important;	
	border-color: black;
}

.semana-vacia{
	background-color: #80a042;
	border-color: #80a042 !important;
}

.sub-headers-lista{
	width: auto !important;
	background-color: red !important;
	color:white !important;
}

/*
.total-puntos:nth-last-child(3){
	background-color: #7cb342 !important;
}
.total-puntos:nth-last-child(2){
	background-color: #8F8E93 !important;
}
.total-puntos:nth-last-child(1){
	background-color: #CCCCCC !important;
}
*/

.tabla-evaluacion{
	margin-top: 0px !important;
}

.regenerar-btn{
	width: 150px !important;
}


.tabla-evaluacion table{
	float: right;
	border:solid;
	border-width: 0.5px;
	border-color: black !important;
	width: 600px !important;
	border-spacing: 0px;
}

.tabla-evaluacion table th{
	border:solid;
	border-width:0.5px;
	border-color: black !important;
	background-color: red !important;
	color: white;
}
.tabla-evaluacion table td{
	text-align: center;
	border:solid;
	border-width:0.5px;
	border-color: black !important;
	background-color: #c5cae9;
}


.tabla-valoracion table{
	float: right;
	margin-top: 10px;
	border:solid;
	border-width: 0.5px;
	border-color: black !important;
	width: 600px !important;
	border-spacing: 0px;	
}

.tabla-valoracion table td,.tabla-valoracion table th{
	text-align: center;
	border:solid;
	border-width:0.5px;

}

.tabla-valoracion table th{
	background-color: #CCCCCC !important;
	color: black !important;
}





.subtitulo-encuadre{
	background-color: black;
	color:white;
}

.NewModal-container iframe {
	width: 100%;
	height: calc(100% - 65px);
}

.select-search__menu {
	z-index: 99999999999999!important;
}
.editable-btn{
	position:absolute;
	left: 300px;
}
.date-year {
    height: 30px;
    box-sizing: border-box;
    width: 60px;
    padding-top: 0px;
    margin-top: 8px!important;
}

.date-year input {
    padding-top: 3px;
    padding-bottom: 3px;
}

.select-docente {
    z-index: 9999999;
    width: 150px;
    font-size: 14px;
}

.select-docente-largo {
    z-index: 9999999;
    width: 140px;
    font-size: 13px
}

@media screen and (max-width: 1230px){
    .select-docente-largo {
        width: 150px
    }
}

@media screen and (max-width: 1150px){
    .select-docente-largo {
        width: 120px
    }
}

textarea.input-clave {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 7px 18px;
    border-radius: 15px;
    outline: none;
    resize: none;
    height: 150px
}

.select-search__menu{
    text-align: center;
}
.select-search__single-value ,.select-search__value-container{
    text-align: center;
}

#root > div[class*="jss"]{
    z-index:99999999 !important
}
.subTable-dash thead tr th {
    background-color: #1B1C20;
    color: #fff;
    font-size: 1.15em;
    height: 50px;
    padding: 5px 10px;
    border-left: 2px solid #fff;
}

.subTable-dash tbody tr td {
    padding: 3px 10px;
    border-left: 2px solid #fff;
}

.subTable-dash tbody tr:nth-child(even) {
    background-color: #eeeeef;
}
.STRight tbody tr:nth-child(even){
	background-color: white;
}

.STRight tbody tr{
	background-color: #eeeeef;
}

.STLeft{
	width: 80% !important;
	float: left;
}

.HeadArrowsST{
	top: 13px !important;
}

.span-rows{
    height: 39px;
    border-left: 0px solid #fff !important;	
    border-bottom:  0px solid #fff !important;	
	text-align: center;
}

.doble-row {
	width: 160px
}

.doble-row .span-rows:first-child {
	width: 60px
}

.doble-row .span-rows:last-child {
	width: 100px
}

.sprr{
	border-left: 2px solid #fff !important;	
}



.STRight{
	width: 20% !important;
	float: left;
	margin-left: 0px;
	overflow-y: auto;
}
.STRight thead tr>th{
	position: static;
	text-align: center;
	height: 28.5px;
 	padding: 0px 0px;
}

.subHeaderContainer{
	padding: 0px 0px !important;
	background-color: #1B1C20 !important; 
}

.subHeaders{
	width: 300px !important;
	border-bottom:  0px solid #fff !important;
	height: 13px !important;
	background-color: #1B1C20;
    color: #fff !important;
	border-left: 0px solid !important;
	position: relative;
}
.doble-row{
	padding: 0px 0px !important;
}
.subHeaderR{
	text-align: right !important;
}

.alcance-green {
    background-color: green;
}

.alcance-yellow {
    background-color: yellow
}

.alcance-red {
    background-color: red
}

.alcance-grey{
	background-color: grey;
}

